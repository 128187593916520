import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GameSelector } from './components/GameSelector';

import './App.css';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/:roomId" element={<GameSelector />} />
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
