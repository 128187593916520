import { useModalContext } from "../../contexts/modal";

import styles from './GameChangeRegionModal.module.css';

export enum GameChangeRegionModalEvents{
  close = 'close',
  cancel = 'cancel',
  change = 'change'
}

export function GameChangeRegionModal() {
  const modalContext = useModalContext();

  const onChange = () => {
    modalContext?.close(GameChangeRegionModalEvents.change);
  };

  const onCancel = () => {
    modalContext?.close(GameChangeRegionModalEvents.cancel);
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalBackground}>
        <div className={styles.titleBlock}>
          <h1 className={styles.title}>
            Do you want to change the game region?
          </h1>
        </div>
        <div className={styles.notice}>
          This will end the current game for everyone
        </div>
        <div className={styles.buttons}>
          <button 
            className={styles.button} 
            onClick={onCancel}
          >
            Cancel
          </button>
          <button 
          className={`${styles.button} ${styles.yes}`} 
          onClick={onChange}
          >
            Change region
          </button>
        </div>
      </div>
    </div>
  );
}
