import React, {createContext, useContext, useEffect, useState} from "react";
import { LatencyRegion } from "../lib/LatencyObserver";

import { useClientContext } from "./client";
import { useServersObserverContext } from "./serversObserver";

type ServerRegion = {
  "id": string
  "display_name": string
  "icon_url": string
  "ping_endpoint": string
};  

const RegionsContext = createContext<null | {
  regions: LatencyRegion[]
  currentRegion: LatencyRegion | null
}>(null)

// adapter to convert websocket messages into react states
export function RegionsContextProvider ({children}: React.PropsWithChildren<{}>) {
  const [regions, setRegions] = useState<LatencyRegion[]>([]);
  const [currentRegion, setCurrentRegion] = useState<LatencyRegion | null>(null);
  const [currentRegionId, setCurrentRegionId] = useState<string>("");

  const clientContext = useClientContext();
  const serverObserverContext = useServersObserverContext();

  const findRegion = (regions: LatencyRegion[], regionId: string) => {
    return regions.find(region => region.id === regionId) ?? null;
  };

  const onRegionsLoad = (getRegions: Array<ServerRegion>) => {
    const newRegions: LatencyRegion[] = getRegions.map(region => {
      return {
        ...region,
        latency: 0
      };
    });

    serverObserverContext?.latencyObserver?.updateRegionsLatency( newRegions, setRegions );

    setRegions(newRegions);
  }

  const onRegionChanged = (newRegionId: string) => {
    setCurrentRegionId( newRegionId );
  }

  useEffect(() => {
    setCurrentRegion( findRegion(regions, currentRegionId) );
  }, [regions, currentRegionId]);

  //get game-regions state
  useEffect(() => {
    if (!clientContext?.client) return;

    clientContext.client.on(clientContext.client.types.getRegions, onRegionsLoad );
    clientContext.client.on(clientContext.client.types.setRegion, onRegionChanged );

    return () => {
      clientContext?.client?.off(clientContext.client.types.getRegions, onRegionsLoad );
      clientContext?.client?.off(clientContext.client.types.setRegion, onRegionChanged );
    }
  }, [clientContext?.client, serverObserverContext?.latencyObserver]);

	return (
    <RegionsContext.Provider value={{
      regions,
      currentRegion
    }}>
      {children}
    </RegionsContext.Provider>
  )
}

export const useRegionsContext = () => useContext(RegionsContext)

