import { useEffect, useState } from "react";
import { useClientContext } from "../contexts/client";
import { useGameStatusContext } from "../contexts/gameStatus";
import { LoadingScreen } from './LoadingScreen';

import styles from "./GameFrame.module.css";

const makeIframeUrl = (url: string) => {
  const newUrl = new URL(url);
  const cURL = new URL(String(document.location));
  newUrl.search = cURL.search;
  return newUrl.toString();
}

export function GameFrame() {
  const [displayIframe, setDisplayIframe] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [gameUrl, setGameUrl] = useState<string>('');
  
  const gameStatusContext = useGameStatusContext();
  const clientContext = useClientContext();

  const onLoadGame = () => {
    if (gameUrl !== ''){
      setIsLoading(false);
      setDisplayIframe(true);
    }
  };

  useEffect(() => {
    if (!clientContext?.client || !gameStatusContext?.gameState) return;

    console.log(gameStatusContext?.gameState);

    if (gameStatusContext?.gameState === 'play' && gameStatusContext?.gameUrl){
      const gameUrl = makeIframeUrl( gameStatusContext?.gameUrl);
      setGameUrl(gameUrl);
    }else{
      setGameUrl('');
    }

    setIsLoading( gameStatusContext?.gameState === 'play' );
    setDisplayIframe( gameStatusContext?.gameState !== 'play' );

    //important to send if Frame is working
    clientContext.client.sendUserStatus( gameStatusContext.gameState );
  }, [clientContext?.client, gameStatusContext?.gameState, gameStatusContext?.gameUrl]);

  return (
    <div>
        {isLoading && 
          <div className={styles.gamePrepare}>
            <LoadingScreen status="Loading game..." />
          </div> 
        }
        {gameUrl && 
         <div className={styles.gameLayer}>
            <iframe 
              className={`${styles.iframe} ${displayIframe ? styles.display : ''}`} 
              src={gameUrl} 
              title="Game" 
              onLoad={onLoadGame} 
            />
          </div>
        }
    </div>
  );
}

