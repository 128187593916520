import React, {createContext, useContext, useEffect, useState} from "react";

import { Client } from "../lib/Client"

const ClientContext = createContext<null | {
	client: Client | null
}>(null)

// adapter to convert websocket messages into react states
export function ClientContextProvider ({children}: React.PropsWithChildren<{}>) {
  const [client, setClient] = useState<null | Client>(null);

  //join to room events
  useEffect(() => {
    const cURL = new URL(String(document.location));
    const userName = cURL.searchParams.get('user_name');
    const token = cURL.searchParams.get('token');
    const categories = cURL.searchParams.get('categories');
    const isAlpha = cURL.searchParams.get('is_alpha');

    const url = process.env.NODE_ENV === 'development' ? ":80/" : '/';

    const newClient = new Client(url, userName, token, categories, isAlpha);

    console.log('set client', newClient);
    setClient(newClient);

    return () => {
      newClient.destroy();
    }
  }, []);

	return (
    <ClientContext.Provider value={{
      client
    }}>
      {children}
    </ClientContext.Provider>
  )
}

export const useClientContext = () => useContext(ClientContext)

