import React, {useEffect, useState} from "react";

import { LoadingScreen } from './LoadingScreen';

import { useClientContext } from "../contexts/client";

type ClientState = 
  "disconnected" |
  "connected" |
  "joined" |
  "error";

export function ConnectionScreen({children, roomId}: React.PropsWithChildren<{roomId: string | undefined}>) {
  const [status, setStatus] = useState<ClientState>("disconnected");
  const [errorStatus, setErrorStatus] = useState<string>("No error");

  const clientContext = useClientContext();

  const onDisconnect = () => {
    setStatus("disconnected")
  }

  const onConnect = () => {
    setStatus("connected");
    clientContext?.client?.joinToRoom( roomId ?? '' );
  }

  const connectedToRoom = () => {
    setStatus("joined")
    clientContext?.client?.getGameStatus();
    clientContext?.client?.getRegions();
  };

  const onError = (error: string) => {
    setErrorStatus(error);
    setStatus("error")
  }

  //join to room events
  useEffect(() => {
    const client = clientContext?.client;
    if (!client) return;

    client.on(client.types.disconnect, onDisconnect );
    client.on(client.types.connect, onConnect );
    client.on(client.types.joinToRoom, connectedToRoom );
    client.on(client.types.joinToRoomError, onError );

    return () => {
      client.off(client.types.disconnect, onDisconnect );
      client.off(client.types.connect, onConnect );
      client.off(client.types.joinToRoom, connectedToRoom );
      client.off(client.types.joinToRoomError, onError );
    }
  }, [clientContext?.client]);

  return (
    <div>
      {status === "disconnected" && <LoadingScreen status="Disconnected..." />}
      {status === "connected" && <LoadingScreen status="Connecting..." />}
      {status === "error" && <LoadingScreen status={`Error: ${errorStatus}`} />}
      {status === "joined" && children}
    </div>
  );
}
