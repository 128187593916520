import { useEffect, useState } from "react";

import { GameStartModal, GameStartModalEvents } from "./GameStartModal";

import { useModalContext } from "../contexts/modal";
import { useClientContext } from "../contexts/client";
import { useGameStatusContext } from "../contexts/gameStatus";

import styles from "./GameCard.module.css"
import { useSelectedGameContext } from "../contexts/selectedGame";

export type Game = {
  id: string;
  title: string;
  description: string;
  thumbnail_url: string;
  minimum_players?: number;
  maximum_players?: number;
}

type GameCardProps = {
  game: Game
}

export function GameCard({game}: GameCardProps) {
  const modalContext = useModalContext();
  const gameStatusContext = useGameStatusContext();
  const clientContext = useClientContext();
  const selectedGameContext = useSelectedGameContext();

  const selected = selectedGameContext?.selectedGameId === game.id;

  const startGame = async () => {
    selectedGameContext?.selectGame(game.id);

    const result = await modalContext?.open<GameStartModalEvents>(
      <GameStartModal 
        game={game} 
        isChangeGame={gameStatusContext?.isChangeGame || false}
        countPlayers={gameStatusContext?.countPlayers || 1}
      />
    );

    selectedGameContext?.deselectGame();

    if (result === GameStartModalEvents.start){
      clientContext?.client?.startGame(game.id);
    }
  };

  let textPlayers = '';
  if (game.minimum_players){
    if (game.maximum_players){
      if (game.maximum_players === game.minimum_players){
        textPlayers = game.minimum_players + (game.minimum_players === 1 ? ' Player' : ' Players');
      }else{
        textPlayers = game.minimum_players + ' - ' + game.maximum_players + ' Players';
      }
    }else{
      textPlayers = game.minimum_players + (game.minimum_players === 1 ? ' Player' : ' Players');
    }
  }else if (game.maximum_players){
    textPlayers = 'Upto ' + game.maximum_players + ' Players';
  }

  return (
    <div className={`${styles.gameCard} ${selected ? styles.opened:''}`} onClick={startGame}>
      <div className={styles.foregroundCard}>
        <div className={styles.continueButton}>
          {gameStatusContext?.isChangeGame ? 'Change game' : 'Start game' }
        </div>
      </div>
      <div className={styles.backgroundCard}>
        <img className={styles.image} src={game.thumbnail_url} alt="" />
        <div className={styles.text}>
          <h3 className={styles.title}>
            {game.title}
          </h3>
          <div className={styles.description}>
            {game.description}
          </div>
        </div>
        {textPlayers?
          <div className={styles.playersCount}>
            {textPlayers}
          </div>:''
        }
      </div>
    </div>
  );
}
