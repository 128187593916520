import { GameCard, Game } from "./GameCard";

import styles from "./GameCategorySection.module.css";

export type GameCategory = {
  id: string;
  title: string;
  interactions: Array<Game>;
}

type GameCategoryProps = {
  category: GameCategory
}

export function GameCategorySection({category}: GameCategoryProps) {
  return (
    <div className={styles.margin}>
      <div className={styles.title}>
        {category.title}
      </div>

      <div className={styles.games}>
        {category.interactions.map(game => 
          <GameCard game={game} key={game.id}/>
        )}
      </div>
    </div>
  );
}
