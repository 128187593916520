import { useRegionsContext } from "../../contexts/regions";
import { Regions, getConnectionQuality } from "./Regions";

import { useState } from "react";

import styles from "./IconsMenu.module.css";
import stylesR from "./Regions.module.css";

export function RegionIcon() {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const regionsContext = useRegionsContext();

  const toggleRegionsList = () => {
    setShowDropdown((oldShow: boolean) => !oldShow);
  };

  const currentLatency = regionsContext?.currentRegion?.latency ?? 300;

  return (
    <div className={`${styles.regionPos} ${styles.icon}`}>
      <img 
        className={`${styles.regionIcon}`} 
        src={regionsContext?.currentRegion?.icon_url} 
        onClick={toggleRegionsList} 
        alt="" 
      />
      <div className={`
        ${styles.connectionIcon} 
        ${ styles[getConnectionQuality(currentLatency)]}
      `}></div>
      <div className={`${styles.hint} ${showDropdown && styles.hide}`}>
        Change the region
      </div>
      <div className={`${stylesR.regionsDropdown} ${showDropdown && stylesR.show}`}>
        <Regions onChange={toggleRegionsList} />
      </div>
    </div>
  );
}