import { useEffect, useState, useRef } from "react";

import { useClientContext } from "../contexts/client";
import { SelectedGameContextProvider } from "../contexts/selectedGame";
import { useGameStatusContext } from "../contexts/gameStatus";
import { GameCategories } from './GameCategories';
import { LoadingScreen } from './LoadingScreen';
import { GameCategory } from "./GameCategorySection";
import { UsersCursors } from "./UsersCursors";

import styles from "./GameCategoriesFrame.module.css";

export function GameCategoriesFrame() {
  const [currentFilter, setCurrentFilter] = useState<string>('ALL');
  const [categories, setCategories] = useState<Array<GameCategory>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const categoriesRef = useRef<HTMLDivElement>(null);
  const centerRef = useRef<HTMLDivElement>(null);

  const gameStatusContext = useGameStatusContext();
  const clientContext = useClientContext();

  const onLoadCategories = (catalog: Array<GameCategory>, filter: string) => {
    setCategories(catalog);
    setCurrentFilter(filter);
    setIsLoading(false);
    onResizeWindow();
  };

  useEffect(() => {
    const client = clientContext?.client;
    if (!client) return;

    client.on(client.types.loadCategories, onLoadCategories );

    setIsLoading(true);
    client.loadCategories();

    return () => {
      client.off(client.types.loadCategories, onLoadCategories );
    };
  }, [clientContext?.client]);

  /* mouse move event */
  const onMouseMove = (ev: any) => {
    const client = clientContext?.client;
    if (!client) return;
    if (!categoriesRef?.current) return;
    if (!centerRef?.current) return;

    const scale = centerRef.current.clientWidth / 1500;

    let mouseX = 0, mouseY = 0;
    if (scale === 1){
      mouseX = ev.clientX - categoriesRef.current.offsetLeft + categoriesRef.current.scrollLeft - centerRef.current.offsetLeft;
      mouseY = ev.clientY - categoriesRef.current.offsetTop + categoriesRef.current.scrollTop - centerRef.current.offsetTop;
    }else{
      mouseX = (ev.clientX) / scale;
      mouseY = (ev.clientY - categoriesRef.current.offsetTop + categoriesRef.current.scrollTop) / scale;
    }

    client.sendUserMouseCoords(mouseX, mouseY);
  };

  //scale down
  const onResizeWindow = () => {
    if (!centerRef?.current) return;

    const scale = centerRef.current.clientWidth / 1500;
    if (scale < 1){
      const x = (1500 - centerRef.current.clientWidth) * scale / 2;
      const height = centerRef.current.scrollHeight * scale;
      const y = height * (1-scale) / 2;

      centerRef.current.style.transform = "scale("+scale+")";
      centerRef.current.style.left = -x+"px";
      centerRef.current.style.top = -y+"px";
      centerRef.current.style.height = height+"px";
    }else{
      centerRef.current.style.transform = '';
      centerRef.current.style.left = '';
      centerRef.current.style.top = '';
      centerRef.current.style.height = '';
    }
  }
  
  useEffect(() => {
    window.addEventListener('resize', onResizeWindow);

    return () => {
      window.removeEventListener('resize', onResizeWindow);
    };
  }, []);

  return (
    <SelectedGameContextProvider>
      <div 
        className={`
          ${styles.gameLayer} ${styles.scrollbar}
          ${gameStatusContext?.showGameCatalog ? styles.show : ''}
          ${gameStatusContext?.isChangeGame ? styles.shifted : ''}
        `} 
        onMouseMove={onMouseMove}
        ref={categoriesRef}
      >
        <div className={styles.backgroundImage}>
          <div className={styles.centerScreen} ref={centerRef}>
            {isLoading 
              ? <LoadingScreen status="Loading games..." /> 
              : <GameCategories categories={categories} onRedraw={onResizeWindow} currentFilter={currentFilter} />
            }
            <UsersCursors />
          </div>
        </div>
      </div>
    </SelectedGameContextProvider>
  );
}