import React from "react";
import { io, Socket } from "socket.io-client";

export type LatencyRegion = {
  "id": string
  "display_name": string
  "icon_url": string
  "ping_endpoint": string
  "latency": number
};

export class LatencyObserver {
  socket: Socket;
  latency: number;
  timer: any;

  start(){
    this.socket.connect();

    return this;
  }

  stop(){
    this.socket.disconnect();

    return this;
  }

  constructor(url: string, onUpdate: (latency: number) => void){
    //url = 'http://localhost/ping';

    this.socket = io( url, { autoConnect: false } );
    this.latency = 0;

    const pingintervalms = 10000;

    this.socket.on('connect', () => {
      this.timer = setInterval(() => {
        this.socket.emit('ping', Date.now());
      }, pingintervalms);

      this.socket.emit('ping', Date.now());
    });

    this.socket.on('pong', (time: number) => {
      this.latency = Date.now() - time;
      onUpdate(this.latency);
    });

    this.socket.on('disconnect', () => {
      clearInterval(this.timer);
    })
  }
}

export class ServersObserver {
  observers: LatencyObserver[];

  destroy(){
    this.observers.forEach(obs => obs.stop());
  }

  updateRegionsLatency(regions: LatencyRegion[], setRegions: React.Dispatch<React.SetStateAction<LatencyRegion[]>>){
    this.observers.forEach(obs => obs.stop());

    this.observers = regions.map(region => new LatencyObserver(region.ping_endpoint, (latency) => {
      region.latency = latency;
      setRegions( regions.slice() );
    }).start());

    return this;
  }

  constructor(){
    this.observers = [];
  }
}
