import { useEffect, useState } from "react";

import { useClientContext } from "../contexts/client";
import { UserCursor, User } from "./UserCursor";

export function UsersCursors() {
  const [users, setUsers] = useState<Array<User>>([]);

  const clientContext = useClientContext();

  const onRefreshUsers = (users: Array<User>) => {
    setUsers( users.filter(user => !user.isCurrentUser) );
  }

  useEffect(() => {
    const client = clientContext?.client;
    if (!client) return;

    client.on(client.types.reloadUsers, onRefreshUsers );

    client.reloadUsers();

    return () => {
      client.off(client.types.reloadUsers, onRefreshUsers );
    };
  }, [clientContext?.client]);

  return (
    <div>
      {users.map(user => <UserCursor user={user} key={user.id} />)}
    </div>
  );
}