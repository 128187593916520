import { io, Socket } from "socket.io-client";

enum ClientCommand {
  connect = "connect",
  disconnect = "disconnect",
  loadCategories = 'load-categories',
  loadCategoriesError = 'load-categories:error',
  gameStatus = 'game-status',
  getRegions = 'get-regions',
  setRegion = 'set-region',
  changeStatus = 'change-user-status',
  changeFilter = 'change-filter',
  userMouseCoords = 'user-mouse',
  userOpenSelector = 'user-open-selector',
  reloadUsers = 'reload-users',
  startGame = 'start-game',
  joinToRoom = 'room-join',
  joinToRoomError = 'room-join:error'
}

export class Client {
  socket: Socket;

  userName: string;
  token: string;
  categories: string;
  isAlpha: string;
  
  readonly types = ClientCommand;
  
	constructor(url: string, userName: string | null, token: string | null, categories: string | null, isAlpha: string | null) {
    this.socket = io(url);

    this.userName = userName || '';
    this.token = token || '';
    this.categories = categories || '';
    this.isAlpha = isAlpha || '';
  }

  loadCategories(){
    console.log(ClientCommand.loadCategories);
    this.socket.emit( ClientCommand.loadCategories, this.token, this.categories, this.isAlpha );
  }

  reloadUsers(){
    console.log(ClientCommand.reloadUsers);
    this.socket.emit( ClientCommand.reloadUsers );
  }

  getGameStatus(){
    console.log(ClientCommand.gameStatus);
    this.socket.emit( ClientCommand.gameStatus );
  }

  getRegions(){
    console.log(ClientCommand.getRegions);
    this.socket.emit( ClientCommand.getRegions, this.token, this.isAlpha );
  }

  setRegion(newRegion: string){
    console.log(ClientCommand.setRegion, newRegion);
    this.socket.emit( ClientCommand.setRegion, this.token, this.isAlpha, newRegion );
  }

  sendFilter(filter: string){
    console.log(ClientCommand.changeFilter, filter);
    this.socket.emit( ClientCommand.changeFilter, filter );
  }

  sendUserStatus(status: string){
    console.log(ClientCommand.changeStatus, status);
    this.socket.emit( ClientCommand.changeStatus, status );
  }

  sendUserMouseCoords(x: number, y: number){
    this.socket.emit( ClientCommand.userMouseCoords, x, y );
  }

  userSetOpenSelector(isOpen: boolean){
    this.socket.emit( ClientCommand.userOpenSelector, isOpen );
  }

  joinToRoom(roomId: string){
    console.log('send join to', roomId);
    this.socket.emit( ClientCommand.joinToRoom, roomId, this.userName, this.token );
  }

  startGame(game_id: string) {
    console.log(ClientCommand.startGame, game_id);
    this.socket.emit( ClientCommand.startGame, game_id, this.token, this.isAlpha );
  }

  isConnected(){
    return this.socket.connected;
  }

  on(command: ClientCommand, fn:(...args:any[]) => void){
    this.socket.on(command, fn);
  }

  off(command: ClientCommand, fn:(...args:any[]) => void){
    this.socket.off(command, fn);
  }

  destroy() {
    this.socket.close();
  }
}