import React, {createContext, useContext, useEffect, useState} from "react";
import { User } from "../components/UserCursor";

import {useClientContext} from "./client";

type GameState = 
  "choose" |
  "prepare" |
  "play";

const GameStatusContext = createContext<null | {
  gameState: GameState
  gameUrl: string
  showGameCatalog: boolean
  toggleGameCatalog: () => void
  countPlayers: number
  isChangeGame: boolean
}>(null)

// adapter to convert websocket messages into react states
export function GameStatusContextProvider ({children}: React.PropsWithChildren<{}>) {
  const [showGameCatalog, setShowGameCatalog] = useState<boolean>(false);
  const [gameState, setGameState] = useState<GameState>("choose");
  const [gameUrl, setGameUrl] = useState<string>("");
  const [countPlayers, setCountPlayers] = useState<number>(1);

  const clientContext = useClientContext();

  const onGameStatusChanged = (newStatus: GameState, url: string) => {
    console.log('change status', newStatus);
    if (url) {
      setGameUrl(url);
    }
    setGameState(newStatus);
  };

  const onUsersReload = (users: Array<User>) => {
    setCountPlayers(users.length);
  }

  //get game-selector state
  useEffect(() => {
    if (!clientContext?.client) return;

    clientContext.client.on(clientContext.client.types.gameStatus, onGameStatusChanged );
    clientContext.client.on(clientContext.client.types.reloadUsers, onUsersReload );

    clientContext.client.getGameStatus();

    return () => {
      clientContext?.client?.off(clientContext.client.types.gameStatus, onGameStatusChanged );
      clientContext?.client?.off(clientContext.client.types.reloadUsers, onUsersReload );
    }
  }, [clientContext?.client]);

  const toggleGameCatalog = () => {
    setShowGameCatalog(oldShow => {
      clientContext?.client?.userSetOpenSelector( !oldShow );

      return !oldShow;
    });
  }

  useEffect(() => {
    setShowGameCatalog( gameState === 'choose' );

    clientContext?.client?.userSetOpenSelector( gameState === 'choose' );
  }, [gameState]);

	return (
    <GameStatusContext.Provider value={{
      gameState,
      gameUrl,
      showGameCatalog,
      toggleGameCatalog,
      countPlayers,
      isChangeGame: gameState !== 'choose'
    }}>
      {children}
    </GameStatusContext.Provider>
  )
}

export const useGameStatusContext = () => useContext(GameStatusContext)

