import { useGameStatusContext } from "../../contexts/gameStatus";

import styles from "./IconsMenu.module.css";

export function CogIcon() {
  const gameStatusContext = useGameStatusContext();

  const toggleGameCatalog = () => {
    gameStatusContext?.toggleGameCatalog();
  }

  return (
    <div className={`${styles.cogPos} ${styles.icon}`}>
      <div 
        className={`${styles.cogIcon}`} 
        onClick={toggleGameCatalog}
      >
      </div>
      <div className={styles.hint}>
        Change the Game
      </div>
    </div>
  );
}