import React, {createContext, useContext, useState } from "react";

import styles from './modal.module.css';

export enum modalCloseEvent {
    close = 'close'
};

const ModalContext = createContext<null | {
    open: <T extends unknown>(modal: JSX.Element) => Promise<T>
    close: (result: any) => void
}>(null);

// context to open a model that can return a number
export function ModalContextProvider ({children}: React.PropsWithChildren<{}>) {
    const [showModalBox, setShowModalBox] = useState<boolean>(false);
    const [modal, setModal] = useState<null | JSX.Element>(null);
    const [promise, setPromise] = useState<(result: any) => void>(() => {});

    const open = <T extends unknown>(modalElem: JSX.Element) => {
        close(modalCloseEvent.close);

        return new Promise<T>(resolve => {
            setShowModalBox(true);

            setModal(modalElem);

            setPromise( () => resolve );
        });
    }

    const close = (result: any) => {
        if (showModalBox){
            setShowModalBox(false);

            promise(result);
        }
    }

    const closeModalOutside = () => {
        close(modalCloseEvent.close);
    }

	return (
        <ModalContext.Provider value={{
            open,
            close,
        }}>
            {children}
            <div className={`${styles.modal} ${showModalBox ? styles.open : styles.noevents}`}>
                <div className={styles.modalOutside} onClick={closeModalOutside}></div>
                <div className={styles.modalInside}>
                    {modal}
                </div>
            </div>
        </ModalContext.Provider>
    )
}

export const useModalContext = () => useContext(ModalContext)

