import { useParams } from "react-router-dom";
import { ConnectionScreen } from './ConnectionScreen';
import { ClientContextProvider } from "../contexts/client";
import { GameStatusContextProvider } from "../contexts/gameStatus";
import { GameCategoriesFrame } from './GameCategoriesFrame';
import { GameFrame } from "./GameFrame";
import { IconsMenu } from "./TopMenu/IconsMenu";
import { ServersObserverContextProvider } from "../contexts/serversObserver";
import { RegionsContextProvider } from "../contexts/regions";
import { ModalContextProvider } from "../contexts/modal";

type Params = {
  roomId: string
};

export function GameSelector() {
  const { roomId } = useParams<Params>();

  return (
    <div>
      <ModalContextProvider>
        <ClientContextProvider>
          <ConnectionScreen roomId={roomId}>
            <GameStatusContextProvider>
              <ServersObserverContextProvider>
                <RegionsContextProvider>
                  <IconsMenu />
                  <GameFrame />
                  <GameCategoriesFrame />
                </RegionsContextProvider>
              </ServersObserverContextProvider>
            </GameStatusContextProvider>
          </ConnectionScreen>
        </ClientContextProvider>
      </ModalContextProvider>
    </div>
  );
}