import { useEffect, useState } from "react";
import { GameCategory, GameCategorySection } from "./GameCategorySection";
import { GameCategoryFilter } from "./GameCategoryFilter";
import { useGameStatusContext } from "../contexts/gameStatus";
import { useClientContext } from "../contexts/client";
import { Game } from "./GameCard";

import styles from './GameCategories.module.css';

type GameCategoriesProps = {
  categories: Array<GameCategory>,
  onRedraw: () => void,
  currentFilter: string
}

export function GameCategories({categories, onRedraw, currentFilter}: GameCategoriesProps) {
  const [filter, setFilter] = useState<string>(currentFilter);

  const gameStatusContext = useGameStatusContext();
  const clientContext = useClientContext();

  const onSelectFilter = (filter: string) => {
    setFilter(filter);
    clientContext?.client?.sendFilter(filter);
  }

  const changeFilter = (filter: string) => {
    setFilter(filter);
  }

  useEffect(() => {
    clientContext?.client?.on(clientContext?.client?.types.changeFilter, changeFilter);

    return () => {
      clientContext?.client?.off(clientContext?.client?.types.changeFilter, changeFilter);
    };
  }, [clientContext?.client])

  useEffect(() => {
    //onredraw
    onRedraw();
  }, [filter, onRedraw]);

  const isSingleCategory = categories.length < 2;

  const filteredCategories = 
    (isSingleCategory) ?
      categories : 
    (filter === 'ALL') ? 
      [{
        id: 'ALL',
        title: 'All games',
        interactions: 
          categories.reduce<Game[]>((acc, val) => {
            return acc.concat(
              val.interactions.filter(
                game => acc.find(newGame => newGame.id === game.id) === undefined
              )
            );
          }, [])
      }] : 
      categories.filter(
        category => category.id === filter
      );

  return (
    <div className={styles.gameCategories}>
      <div className={styles.header}>
        { gameStatusContext?.isChangeGame 
          ? 'Change the Game' 
          : 'Select a Game' 
        }
      </div>
      {!isSingleCategory && 
        <div className={styles.filters}>
          <div className={styles.filterTitle}>
            Filter by players:
          </div>
          <GameCategoryFilter
            category={{id: "ALL", title: "All games"}}
            isSelected={filter === 'ALL'}
            onSelect={onSelectFilter}
          />
          {categories.map(category => 
            <GameCategoryFilter
              category={category} 
              key={category.id}
              isSelected={filter === category.id}
              onSelect={onSelectFilter}
            />
          )}
        </div>
      }
      {filteredCategories.map(category => 
        <GameCategorySection 
          category={category} 
          key={category.id} 
        />
      )}
    </div>
  );
}
