import styles from './GameCategoryFilter.module.css';

export function GameCategoryFilter({category, isSelected, onSelect}: {category: {id: string, title: string}, isSelected: boolean, onSelect: (filter: string) => void}) {
  const onClickFilter = () => {
    onSelect(category.id);
  }

  return (
    <div className={`${styles.gameFilter} ${isSelected && styles.selected}`} onClick={onClickFilter}>
      {category.title}
    </div>
  );
}
