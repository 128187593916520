import { useClientContext } from "../../contexts/client";
import { useGameStatusContext } from "../../contexts/gameStatus";
import { useModalContext } from "../../contexts/modal";
import { useRegionsContext } from "../../contexts/regions";
import { LatencyRegion } from "../../lib/LatencyObserver";
import { GameChangeRegionModal, GameChangeRegionModalEvents } from "./GameChangeRegionModal";

import styles from "./Regions.module.css";

export type ConnectionQuality = 'good' | 'average' | 'bad';

export function getConnectionQuality(latency: Number): ConnectionQuality{
  if (latency < 60) return 'good';
  if (latency < 200) return 'average';
  return 'bad';
}

export function Regions({onChange}: {onChange: () => void}) {
  const modalContext = useModalContext();
  const gameStatusContext = useGameStatusContext();
  const regionsContext = useRegionsContext();
  const clientContext = useClientContext();

  const onChangeRegion = async (region: LatencyRegion) => {
    if (gameStatusContext?.gameState === 'play'){
      const result = await modalContext?.open<GameChangeRegionModalEvents>(
        <GameChangeRegionModal />
      );

      if (result !== GameChangeRegionModalEvents.change) return;
    }

    clientContext?.client?.setRegion(region.id);
    onChange();
  }

  return (
    <div className={styles.regions}>
      <div className={styles.title}>Select the game region:</div>
      <div className={styles.separator}></div>
      {regionsContext?.regions.map(region => {
        return <div key={region.id}
          className={`${styles.region} ${(regionsContext.currentRegion === region) && styles.selected}`}
          onClick={() => onChangeRegion(region)}
        >
          <img src={region.icon_url} className={styles.icon} alt={region.display_name} />
          <div className={`${styles.connectionIcon} ${ styles[getConnectionQuality(region.latency)]}`}></div>
          <div className={styles.regionName}>{region.display_name}</div>
          <div className={styles.regionPing}>{region.latency} ms</div>
        </div>
      })}
    </div>
  );
}