import { useRef, useEffect } from "react";

import { useClientContext } from "../contexts/client";

import styles from "./UserCursor.module.css";

export type User = {
  id: string;
  name: string;
  x: number,
  y: number,
  open: boolean,
  isCurrentUser: boolean
}

type UserProps = {
  user: User
}

function HSV(h: number, s: number, v: number) {
  var r = 0, g = 0, b = 0, i, f, p, q, t;

  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
      case 0: r = v; g = t; b = p; break;
      case 1: r = q; g = v; b = p; break;
      case 2: r = p; g = v; b = t; break;
      case 3: r = p; g = q; b = v; break;
      case 4: r = t; g = p; b = v; break;
      case 5: r = v; g = p; b = q; break;
  }
  return "rgb("+ Math.round(r * 255) +", "+  Math.round(g * 255) +", "+ Math.round(b * 255) +")";
}

export function UserCursor({user}: UserProps) {
  const clientContext = useClientContext();
  const userRef = useRef<HTMLDivElement>(null);

  const color = HSV(((parseInt(user.id) - 1) * 0.618033988749895) % 1.0, 0.9, 0.7);

  const onUserMouseMove = (newUser: User) => {
    if (!userRef?.current) return

    if (user.id === newUser.id){
      //change coords
      userRef.current.style.top = newUser.y+"px";
      userRef.current.style.left = newUser.x+"px";

      userRef.current.className = newUser.open ? styles.userCursor : `${styles.userCursor} ${styles.hide}`;
    }
  }

  useEffect(() => {
    const client = clientContext?.client;
    if (!client) return;

    client.on(client.types.userMouseCoords, onUserMouseMove );

    return () => {
      client.off(client.types.userMouseCoords, onUserMouseMove );
    };
  }, [clientContext?.client]);

  return (
    <div className={styles.userCursor} ref={userRef}>
      <svg className={styles.cursorSvg} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#user-filter)">
          <path d="M6.32665 12.3763L5.81219 9.70113C5.26372 6.84906 4.98948 5.42303 5.59242 4.69605C5.81919 4.42262 6.11469 4.21453 6.44855 4.09316C7.33619 3.77048 8.58639 4.50924 11.0868 5.98675L13.1208 7.18865C14.2439 7.85232 14.8055 8.18416 15.0428 8.49639C15.7094 9.37355 15.5411 10.6246 14.6664 11.2944C14.355 11.5329 13.7073 11.7095 12.412 12.0628C12.2167 12.116 12.1191 12.1426 12.0302 12.1762C11.6695 12.3124 11.3559 12.55 11.1271 12.8604C11.0708 12.9368 11.0203 13.021 10.9192 13.1893C10.3702 14.1043 10.0958 14.5618 9.87118 14.7632C8.90453 15.63 7.38103 15.3603 6.7707 14.2143C6.62891 13.9481 6.52816 13.4241 6.32665 12.3763Z" 
            style={{
              fill:color 
            }}/>
          <path d="M6.20058 14.5179L6.7707 14.2143L6.20058 14.5179C7.00802 16.034 9.02356 16.3908 10.3024 15.2441C10.4838 15.0814 10.6481 14.8525 10.8149 14.5963C10.9867 14.3324 11.1975 13.981 11.4659 13.5337L11.4731 13.5217C11.5804 13.3429 11.6131 13.2897 11.647 13.2437C11.8019 13.0336 12.0143 12.8727 12.2585 12.7805C12.3118 12.7603 12.3743 12.7426 12.5819 12.6859L12.5997 12.6811C13.2323 12.5086 13.7304 12.3727 14.1082 12.2497C14.4764 12.1298 14.8089 11.9988 15.0591 11.8073C16.2163 10.9211 16.4389 9.26602 15.5571 8.10556C15.3673 7.85588 15.087 7.64459 14.7699 7.43525C14.4459 7.22142 14.0135 6.96588 13.4657 6.64216L13.4494 6.63255L11.4154 5.43065L11.3771 5.40801C10.1593 4.68841 9.19616 4.11925 8.4265 3.77519C7.66469 3.43463 6.92407 3.23301 6.22786 3.48609C5.78618 3.64666 5.39524 3.92196 5.09523 4.2837C4.62233 4.85389 4.56257 5.61914 4.62649 6.45115C4.69106 7.29174 4.90234 8.39038 5.16947 9.77941L5.17788 9.82311L5.69233 12.4983L5.69498 12.5121C5.79349 13.0243 5.87088 13.4267 5.94166 13.7336C6.01037 14.0315 6.08604 14.3029 6.20058 14.5179Z"
            style={{
              stroke: "white", 
              strokeWidth: "1.29187",
              strokeLinecap: "square"
            }}/>
        </g>
        <defs>
          <filter id="user-filter" x="0.0833704" y="0.14282" width="20.5345" height="21.5914" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity = "0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="1.29187"/>
            <feGaussianBlur stdDeviation="1.93781"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
        </defs>
      </svg>
                        
        <div className={styles.cursorName} style={{ backgroundColor: color }}>{user.name}</div>
    </div>
  );
}
