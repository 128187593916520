import React, {createContext, useContext, useEffect, useState} from "react";

import { ServersObserver } from "../lib/LatencyObserver";

const ServersObserverContext = createContext<null | {
	latencyObserver: ServersObserver | null
}>(null)

// adapter to convert websocket messages into react states
export function ServersObserverContextProvider ({children}: React.PropsWithChildren<{}>) {
  const [latencyObserver, setLatencyObserver] = useState<null | ServersObserver>(null);

  //join to room events
  useEffect(() => {
    const newLatencyObserver = new ServersObserver();

    setLatencyObserver(newLatencyObserver);

    return () => {
      newLatencyObserver.destroy();
    }
  }, []);

	return (
    <ServersObserverContext.Provider value={{
      latencyObserver
    }}>
      {children}
    </ServersObserverContext.Provider>
  )
}

export const useServersObserverContext = () => useContext(ServersObserverContext)

