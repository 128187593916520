import { useGameStatusContext } from "../../contexts/gameStatus";
import { CogIcon } from "./CogIcon";
import { RegionIcon } from "./RegionIcon";

import styles from "./IconsMenu.module.css";

export function IconsMenu() {
  const gameStatusContext = useGameStatusContext();

  return (
    <div className={`${styles.topRightIcons} ${gameStatusContext?.isChangeGame && styles.inGame}`}>
      <RegionIcon />
      <CogIcon />
    </div>
  );
}