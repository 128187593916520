import React, {createContext, useContext, useState} from "react";

const SelectedGameContext = createContext<null | {
	selectGame: (gameId: string) => void;
	deselectGame: () => void;
	selectedGameId: string | null
}>(null)

// adapter to convert websocket messages into react states
export function SelectedGameContextProvider ({children}: React.PropsWithChildren<{}>) {
  const [selectedGameId, setSelectedGameId] = useState<string | null>(null);

  const selectGame = (gameId: string) => {
    setSelectedGameId(gameId);
  }

  const deselectGame = () => {
    setSelectedGameId(null);
  }

	return (
    <SelectedGameContext.Provider value={{
      selectGame,
      deselectGame,
      selectedGameId
    }}>
      {children}
    </SelectedGameContext.Provider>
  )
}

export const useSelectedGameContext = () => useContext(SelectedGameContext)

