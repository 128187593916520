import { useModalContext } from "../contexts/modal";
import { Game } from "./GameCard";

import styles from './GameStartModal.module.css';

export enum GameStartModalEvents{
  close = 'close',
  cancel = 'cancel',
  start = 'start'
}

type GameCardProps = {
  game: Game,
  isChangeGame: boolean,
  countPlayers: number
}

export function GameStartModal({game, isChangeGame, countPlayers}: GameCardProps) {
  const modalContext = useModalContext();

  const onStart = () => {
    modalContext?.close(GameStartModalEvents.start);
  };

  const onCancel = () => {
    modalContext?.close(GameStartModalEvents.cancel);
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalBackground}>
        <div className={styles.titleBlock}>
          <h1 className={styles.title}>
            Do you want to {isChangeGame && <br />}{isChangeGame ? 'change the game to ' : 'start' }<br />{game.title} {isChangeGame ? '?' : (countPlayers > 1 ? <div>for everyone at the table?</div> : '?')}
          </h1>
          <img className={styles.image} src={game.thumbnail_url} alt="" />
        </div>
        { isChangeGame && 
          <div className={styles.notice}>
            The current game will be over {countPlayers > 1 ? 'for everyone' : ''}
          </div>
        }
        <div className={styles.buttons}>
          <button 
            className={styles.button} 
            onClick={onCancel}
          >
            Cancel
          </button>
          <button 
          className={`${styles.button} ${styles.yes}`} 
          onClick={onStart}
          >
            { isChangeGame ? 'Change game' : 'Start game' } {countPlayers > 1 && 'for everyone'}
          </button>
        </div>
      </div>
    </div>
  );
}
